/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.responsive {
  display: block;
  max-width: 100%;
}

.picture {
  width: 100%;
}

.textContainer {
  cursor: pointer;
  -webkit-transition: all 1s ease-in;
  -o-transition: all 1s ease-in;
  transition: all 1s ease-in;
  position: absolute;
  top: 55%;
  left: 50%;
  -webkit-transform: translate(-50%, 20%);
  -ms-transform: translate(-50%, 20%);
  transform: translate(-50%, 20%);
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  padding: 15px;
  width: 100%;
  height: 110%;
  border-radius: 2rem;
}
.textContainer:hover {
  background-color: rgba(255, 255, 255, 0.9);
}
.slideUp {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.textContainer.slideUp {
  overflow-y: auto;
}
.textHeader {
  font-size: 1.4rem !important;
  color: rgb(15 38 45 / 90%);
  letter-spacing: 2px !important;
  padding: 0 15px;
  -webkit-transform: translate(0%, 0%);
  -ms-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
}

.textContainer.slideUp .textHeader {
  padding-top: 10px;
}
.textBody {
  padding: 10px 25px 35px 25px;
  opacity: 1;
}
.superscript-icon {
  color: rgb(15 38 45 / 50%);
  font-size: 0em; /* Adjust size relative to parent text */
  vertical-align: super; /* Aligns the icon as superscript */
  line-height: 0; /* Prevents extra spacing */
}
.superscript-icon svg {
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: -webkit-transform 0.5s ease-in-out;
  -o-transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.textContainer.slideUp .superscript-icon svg {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.disappear {
  opacity: 0;
}

.circleImg {
  border-radius: 50%;
}

@media (min-width: 500px) {
  .textHeader {
    -webkit-transform: translate(0%, 100%);
    -ms-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
  }
  .textContainer.slideUp .textHeader {
    -webkit-transform: translate(0%, 10%);
    -ms-transform: translate(0%, 10%);
    transform: translate(0%, 10%);
  }
}
@media( min-width:650px) and (max-width:1019px){
   .textContainer {
    top: 48%;
  }
 .textHeader {
  font-size: 1.2rem !important;
  -webkit-transform: translate(-2%, 0%);
  -ms-transform: translate(-2%, 0%);
  transform: translate(-2%, 0%); 
} 
}
@media (min-width: 725px) {
  .textContainer {
    top: 50%;
  }
  .textHeader {
    -webkit-transform: translate(0%, 10%);
    -ms-transform: translate(0%, 10%);
    transform: translate(0%, 10%);
  }
}
